var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"testimonialWrap py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"customer-title text-center"},[_c('h2',[_vm._v(_vm._s(_vm.student_counter)+"+ students and counting")])])]),_vm._m(0)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[(_vm.feedback.data.length > 0)?[_c('carousel',{attrs:{"autoplay":true,"nav":false,"items":4,"margin":20,"responsive":{
                    0:{items:1},
                    600:{items:2},
                    992:{items:2},
                    1200:{items:4}
                    }}},_vm._l((_vm.feedback.data),function(item,i){return _c('div',{key:i,staticClass:"testimonail-block"},[_c('div',{staticClass:"customer-detail"},[_c('img',{attrs:{"src":item.user.avatar_path,"alt":""}}),_c('div',[_c('h3',[_vm._v(_vm._s(item.user.name))]),_c('star-rating',{attrs:{"rating":item.rating,"read-only":true,"star-size":20,"show-rating":false}})],1)]),_c('div',{staticClass:"customer-feedback"},[_vm._v(" "+_vm._s(item.feedback)+" ")])])}),0)]:_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-4"},[_c('div',{staticClass:"testimonial-title text-center"},[_c('h2',[_vm._v("What my customers are "),_c('br'),_vm._v(" saying")])])])
}]

export { render, staticRenderFns }