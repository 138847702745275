<template>
    <section class="testimonialWrap py-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="customer-title text-center">
                        <h2>{{student_counter}}+ students and counting</h2>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="testimonial-title text-center">
                        <h2>What my customers are <br> saying</h2>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <template v-if="feedback.data.length > 0">
                        <carousel :autoplay="true" :nav="false" :items="4" :margin="20"
                        :responsive="{
                        0:{items:1},
                        600:{items:2},
                        992:{items:2},
                        1200:{items:4}
                        }">
                            <div class="testimonail-block" v-for="(item,i) in feedback.data" :key="i">
                                <div class="customer-detail">
                                    <img :src="item.user.avatar_path" alt="">
                                    <div>
                                        <h3>{{item.user.name}}</h3>
                                        <star-rating :rating="item.rating" :read-only="true" :star-size="20" :show-rating="false"></star-rating>
                                    </div>
                                </div>
                                <div class="customer-feedback">
                                    {{ item.feedback }}
                                </div>
                            </div>
                        </carousel>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import carousel from 'vue-owl-carousel'
import StarRating from 'vue-star-rating'
export default {
    name: "Testimonials",
    components: {
        StarRating,
        carousel
    },
    data() {
        return {
            student_counter:0,
            feedback:{
                data: [],
            },
        };        
    },
    mounted() {
        let app = this;
        app.renderData();
    },
    methods: {
        async renderData(){
            let app = this;
            await axios.get(api.getUrl('/featured/feedbacks')).then(function (response) {
                if(response.data.success){
                    let responseData   = response.data.data;
                    app.student_counter= responseData.count;
                    app.feedback.data  = responseData.data;
                }
            });
        },
    },
}
</script>