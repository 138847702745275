<template>
    <section class="faq-wrap py-5">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h2>Frequently Asked Questions</h2>
                </div>
                <div class="col-12 mt-4">
                    <div class="accordion"  v-if="faqs.data.length > 0" >
                        <b-card no-body class="mb-3" v-for="(item,i) in faqs.data" :key="i" >
                            <b-card-header header-tag="header" >
                                <b-button block v-b-toggle="'accordion-'+i" class="d-block w-100" variant="info">
                                    <span>{{item.question}}</span>
                                    <span class="icon-down"></span>
                                </b-button>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+i" visible accordion="my-accordion" >
                                <b-card-body>
                                <b-card-text> {{item.answer}} </b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
export default {
    name: "Faq",
    data() {
        return {
            faqs:{
                data: [],
            },
        };        
    },
    mounted() {
        let app = this;
        app.renderData();
    },
    methods: {
        async renderData(){
            let app = this;
            await axios.get(api.getUrl('/faq')).then(function (response) {
                if(response.data.success){
                    app.faqs.data = response.data.data;
                }
            });
        },
    },
}
</script>