<template>
    <section class="bannerWrap">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-4 col-lg-4 col-md-5">
                    <template v-if="banners.length > 0">
                    <carousel :autoplay="true" :nav="false" :items="1" :margin="20" :mouseDrag="true" >
                        <div class="mainCourse" v-for="(item,i) in banners" :key="i" >
                            <template v-if="item.target_url !=null"> 
                                <router-link :to="item.target_url">
                                    <img :src="item.image_web" class="img-fluid desktop-view" alt="banner" >
                                    <img :src="item.image_mob" class="img-fluid mobile-view" alt="banner" >
                                </router-link>
                            </template>
                        </div>
                    </carousel>
                    </template>
                </div>
                <div class="col-xl-1 col-1"></div>
                <div class="col-xl-5 col-lg-5 col-md-5">
                    <div class="bannerText">
                        <div v-html="description"></div>
                        <router-link :to="target_url" class="univ-btn mt-4">{{target_url_label}}<span class="icon-arrow"></span></router-link>
                        <span class="icon-quote">
                            <img src="../../../assets/images/icons/quote.svg" class="img-fluid" alt="quote" >
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import carousel from 'vue-owl-carousel'
export default {
    name: "Banner",
    components: {
        carousel
    },
    data() {
        return {
            banners:[],
            description:"",
            target_url_label:"Learn My Courses",
            target_url:"",
        }
    },
    mounted() {
        let app = this;
        app.renderBannerInfo();
    },
    methods: {
        async renderBannerInfo(){
            let app = this;
            await axios.get(api.getUrl('/home/banner')).then(function (response) {
                let result = response.data;
                if(result.success){
                    app.banners = result.data.data;
                    app.description      = result.data.description;
                    app.target_url_label= result.data.target_url_label;
                    app.target_url= result.data.target_url;
                }
            });
        }
    }
}
</script>