<template>
    <div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-11">
                    <div class="subscribe-wrap">
                        <div class="row align-items-center">
                            <div class="col-xl-5">
                                <h2>Subscribe</h2>
                                <p>Subscribe to my Newsletter to get notified for new offers and courses</p>
                            </div>
                            <div class="col-xl-7">
                                <div class="subscribe-input">
                                    <input type="email" v-model="formReq.email" placeholder="Enter your email address">
                                    <button @click="formSubmit()" > <b-spinner v-if="loader" small></b-spinner>  Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios"
import api from "@/services/api"
export default{
    name: 'Newsletter',
    data(){
        return {
            formReq:{
                name:'',
                email: '',
            },
            formErrors: {
                email: "",
            },
            loader:false,
        }
    },
    methods: {
        formSubmit(){
            let app = this;
            app.clearedError();
            app.loader = true;
            axios.post(api.getUrl('/subscriber/store'),app.formReq).then((response) => {
                if(response.data.success == true){
                    app.loader = false;
                    app.$toast.success(response.data.message,{position: "bottom-right",timeout: 2000});
                }else{
                    if(response.data.message){
                        app.loader = false;
                        app.$toast.error(response.data.message,{position: "bottom-right",timeout: 2000});
                    }else if(response.data.errors.length > 0){
                        app.loader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "email"){
                                app.$toast.error(item.message,{position: "bottom-right",timeout: 2000});
                            }
                        }); 
                    }
                }
            });
        },
        clearedError(){
            let app = this;
            app.formErrors.email        = "";
        },
    },
}
</script>