<template>
    <section class="onlineCourseWrap py-4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title">
                        <div>
                            <h2>{{title}}</h2>
                            <div v-html="description"></div>
                        </div>
                        <router-link :to="{ name: 'Courses'}"> View All <span class="icon-arrow"></span> </router-link>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3" v-for="(item,i) in courses.data" :key="i">
                    <CourseItem :item="item"/>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import axios from 'axios'
import api from '@/services/api'
import CourseItem from '@/components/CourseItem'
export default {
    name: "OnlineCourse",
    components: { 
        CourseItem
    },
    data() {
        return {
            title:"",
            description:"",
            courses: { 
                data: [],
            },
        };        
    },
    mounted() {
        let app = this;
        app.renderCourses();
    },
    methods: {
        async renderCourses(){
            let app = this;
            await axios.get(api.getUrl('/home/courses')).then(function (response) {
                if(response.data.success){
                    let responseData     = response.data.data;
                    app.title       = responseData.title;
                    app.description = responseData.description;
                    app.courses.data = responseData.data;
                }
            });
        },
    }
}
</script>