<template>
    <div class="home-view">
        <vue-scroll-snap >
            <div class="item">
                <Banner />
            </div>
            <div class="item">
                <OnlineCourse />
            </div>
            <div class="item">
                <ShopStuff />
            </div>
            <div class="item">
                <Testimonials />
            </div>
            <div class="item">
                <LatestBlogs />
            </div>
            <div class="item">
                <Faq />
                <Newsletter />
            </div>
        </vue-scroll-snap>
    </div>
</template>
<script>
import VueScrollSnap from "vue-scroll-snap"
import OnlineCourse from './partials/OnlineCourse.vue'
import Banner from './partials/Banner.vue'
import ShopStuff from './partials/ShopStuff.vue'
import Testimonials from './partials/Testimonials.vue'
import LatestBlogs from './partials/LatestBlogs.vue'
import Faq from './partials/Faq.vue'
import Newsletter from '../../components/Newsletter.vue'
export default {
    name: 'Home',    
    components: {
        VueScrollSnap,
        Banner,
        OnlineCourse,
        ShopStuff,
        Testimonials,
        LatestBlogs,
        Faq,
        Newsletter
    },
}
</script>
<style src="../../assets/css/home.css"></style>