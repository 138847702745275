<template>
    <section class="py-5">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="blogWrap">
                        <div class="section-title">
                            <h2>Latest Blogs</h2>
                            <router-link to="/blogs">View All</router-link>
                        </div>
                        <ul>
                            <li v-for="(item,i) in blogs.data" :key="i" >
                                <router-link :to="{name:'BlogDetail', params: {slug:item.slug} }" >
                                    <div class="blog-list">
                                        <div class="blog-img">
                                            <img :src="item.image" class="img-fluid" :alt="item.title">
                                        </div>
                                        <div>
                                            <h3>{{item.title}}</h3>
                                            <div v-html="item.short_description"></div>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-xl-5 col-lg-6 col-md-6">
                    <div class="row">
                        <div class="col-xl-6 col-sm-6 col-6 ">
                            <div class="counter-data">
                                <h3>{{counter.courses}}</h3>
                                <p>Courses</p>
                            </div>
                            <div class="counter-data dark-bg mt-4">
                                <h3>{{counter.blogs}}</h3>
                                <p>Blogs</p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-sm-6 col-6 ">
                            <div class="counter-data dark-bg mt-4">
                                <h3>{{counter.videos}}</h3>
                                <p>Videos</p>
                            </div>
                            <div class="counter-data mt-4">
                                <h3>{{counter.subscribers}}</h3>
                                <p>Subscribers</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
export default {
    name: "LatestBlogs",
    data() {
        return {
            blogs:{
                data: [],
            },
            filterData:{
                limit: 3,
            },
            counter:[],
        };      
    },
    mounted() {
        let app = this;
        app.renderData();
        app.renderCounter();
    },
    methods: {
        async renderData(){
            let app = this;
            await axios.post(api.getUrl('/search/posts'), app.filterData).then(function (response) {
                if(response.data.success){
                    let responseData = response.data.data;
                    app.blogs.data   = responseData.data;
                }
            });
        },
        async renderCounter(){
            let app = this;
            await axios.get(api.getUrl('/counter')).then(function (response) {
                if(response.data.success){
                    app.counter = response.data.data.counter;
                }
            });
        },
    }
}
</script>